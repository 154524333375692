import { getInstance } from '@/auth'

const auth = getInstance()

export const navLinks = [
    {
        title: `your rmas`,
        disabled: false,
        route: `rma-history`
    },
    {
        title: 'your quotes',
        disabled: false,
        route: `saved-quotes`
    },
    {
        title: `your parts orders `,
        disabled: false,
        route: `parts-orders-history`
    },
    ...(auth.userInOrganization()
        ? [
              {
                  title: `branches`,
                  disabled: false,
                  route: `branches`
              }
          ]
        : []),
    {
        title: `product registration `,
        disabled: false,
        route: `product-registration`
    },
    {
        title: `account settings `,
        disabled: false,
        route: `account-settings`
    },
    {
        title: `dashboard`,
        disabled: false,
        route: `dashboard`
    },
    {
        title: `drivers`,
        disabled: false,
        route: `drivers`
    }
]
