export function relayPagination(queryName) {
    return {
        data() {
            return {
                _startCursor: '',
                _endCursor: ''
            }
        },
        computed: {
            serverItemsLength() {
                return (
                    this.$apollo.data[queryName] &&
                    this.$apollo.data[queryName].totalCount
                )
            },
            serverItemsLoading() {
                return this.$apollo.queries[queryName].loading
            },
            displayedTableItems() {
                const page = this.options.page
                const offset = (page - 1) * this.options.itemsPerPage

                return (
                    (this[queryName] &&
                        this[queryName].edges
                            .map((e) => e.node)
                            .slice(offset)
                            .slice(0, this.options.itemsPerPage)) ||
                    []
                )
            }
        },
        methods: {
            lookForMoreServerItems({ page, itemsPerPage }) {
                if (this.displayedTableItems.length !== itemsPerPage)
                    this.fetchMoreServerItems({ page, itemsPerPage })
            },

            refetchTableData() {
                this.$apollo.queries[queryName].fetchMore({
                    variables: {
                        after: this._endCursor,
                        before: this._startCursor,
                        first: this.options.itemsPerPage,
                        search: (this.search || ``).trim(),
                        healthy: this.showHealthy,
                        unHealthy: this.showUnHealthy,
                        sort: {
                            key: this.options.sortBy[0],
                            descending: this.options.sortDesc[0]
                        }
                    }
                })
            },

            fetchMoreServerItems({ page, itemsPerPage }) {
                this._startCursor =
                    page === 1
                        ? undefined
                        : this[queryName]?.pageInfo?.startCursor
                this._endCursor =
                    page === 1
                        ? undefined
                        : this[queryName]?.pageInfo?.endCursor

                this.$apollo.queries[queryName].fetchMore({
                    variables: {
                        first: itemsPerPage,
                        before: this._startCursor,
                        after: this._endCursor
                    }
                })
            }
        }
    }
}
